<template>
  <b-overlay :show="busy" rounded="sm">
    <div class="catalogue-section-container">
      <div class="catalogue-section">
        <div v-if="loadingCatalogues">Loading ...</div>
        <div v-else>
          <div class="button-section">
            <span>
              <b-button class="ml-2 top_btn" size="sm" v-on:click="onAddCatalogue">
                <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Add Catalogue</b-button
              >
            </span>
            <span>
              <b-button class="ml-2 top_btn" size="sm" variant="secondary" v-on:click="onClickPreview">
                <font-awesome-icon :icon="['fas', 'eye']" class="mr-1" />
                Customer View</b-button
              >
            </span>
            <span v-if="ifPermission()">
              <b-button class="ml-2 top_btn" size="sm" variant="secondary" v-on:click="onClickCopy(url)">
                <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />
                Customer Link</b-button
              >
            </span>
            <span>
              <b-button
                v-if="selectedCatalogue"
                class="ml-2 top_btn"
                size="sm"
                variant="secondary"
                v-on:click="selectCata(selectedCatalogue)"
              >
                <font-awesome-icon :icon="['fas', 'trash-alt']" class="mr-1" />
                Delete</b-button
              ></span
            >
          </div>
          <div v-if="isAddingCatalogue" class="list-item catalogue-title">
            <label class="catalogue-title-label">Add New Catalogue</label>
            <br />
            <input v-model="catalogueTitleText" id="add-catalogue-title-text" />
            <div>
              <b-button
                class="ml-2 top_btn"
                size="sm"
                variant="success"
                v-on:click="onSaveNewCatalogue(catalogueTitleText)"
              >
                Save
              </b-button>
              <b-button class="ml-2 top_btn" size="sm" variant="fail" v-on:click="onCloseAddCatalogue">
                Cancel
              </b-button>
            </div>
          </div>
          <div v-for="catalogueLocal in catalogues" :key="catalogueLocal.id">
            <button
              v-if="catalogues != ''"
              class="list-item catalogue-title"
              v-on:click="() => local(catalogueLocal)"
              :style="
                `background: ${selectedCatalogueLocal === catalogueLocal ? '#f28a3e' : 'none'}; color: ${
                  selectedCatalogueLocal === catalogueLocal ? '#fff' : '#42526E'
                };`
              "
              >{{ catalogueLocal }}</button
            >
          </div>
          <div v-for="catalogue in this.newGroup" :key="catalogue.id">
            <button
              class="list-item catalogue-title"
              v-on:click="() => getProducts(catalogue)"
              :style="
                `background: ${selectedCatalogue === catalogue ? '#f28a3e' : 'none'}; color: ${
                  selectedCatalogue === catalogue ? '#fff' : '#42526E'
                };`
              "
            >
              {{ catalogue }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="ifPermission()" class="product-section">
        <div>
          <div v-if="showButtons()">
            <div class="button-section">
              <span>
                <b-button
                  :disabled="ifSelecetdProducts() ? false : true"
                  class="ml-2 top_btn"
                  size="sm"
                  variant="primary"
                  v-on:click="onClickApprove"
                >
                  <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Approve Products</b-button
                >
              </span>
              <span>
                <b-button
                  :disabled="ifSelecetdProducts() ? false : true"
                  class="ml-2 top_btn"
                  size="sm"
                  variant="success"
                  v-on:click="onClickDisApprove"
                >
                  <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Disapprove Products</b-button
                >
              </span>
              <div style="margin-bottom: -8px; margin-top: -5px">
                <input :checked="allSelected" @click="selectAll" class="checkboxAll" type="checkbox" id="scales" />
                <label class="allSelectLabel" for="scales">Select All Products</label>
              </div>
            </div>
            <button
              v-for="product in newProducts"
              :key="product._id"
              class="list-item product-list"
              v-on:click="() => onSelectProduct(product)"
              :style="
                `background: ${selectedProduct === product._id ? '#f28a3e' : 'none'}; color: ${
                  selectedProduct === product._id ? '#fff' : '#42526E'
                };`
              "
            >
              <input
                class="checkboxActive"
                :checked="selectedProducts.includes(product._id)"
                @click="select(product)"
                type="checkbox"
              />
              <span class="product-title">
                {{ product.description }}
              </span>
              <span v-b-tooltip.hover title="Active" v-if="product.active === true" class="activeProduct">●</span>
              <span v-b-tooltip.hover title="Inactive" v-if="product.active === false" class="inactiveProduct">●</span>
            </button>
          </div>
          <div class="productText" v-else>Please select atleast one catalogue to view products.</div>
        </div>
      </div>
      <div v-else class="product-section">
        <div>
          <div v-if="showButtons()">
            <div class="button-section">
              <span>
                <b-button class="ml-2 top_btn" size="sm" variant="primary" v-on:click="onClickAddProduct">
                  <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Add New Product</b-button
                >
              </span>
              <span>
                <b-button class="ml-2 top_btn" size="sm" variant="success" v-on:click="onClickUploadProduct">
                  <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload</b-button
                >
              </span>
            </div>
            <button
              v-for="product in newProducts"
              :key="product._id"
              class="list-item product-list"
              v-on:click="() => onSelectProduct(product)"
              :style="
                `background: ${selectedProduct === product._id ? '#f28a3e' : 'none'}; color: ${
                  selectedProduct === product._id ? '#fff' : '#42526E'
                };`
              "
            >
              <span class="product-title">
                {{ product.description }}
              </span>
            </button>
          </div>
          <div class="productText" v-else>Please select atleast one catalogue to view products.</div>
        </div>
      </div>
      <div class="single-product-section">
        <div v-if="isProductFormOpen">
          <b-form-group class="input_label" id="title" label-for="input-title">
            <template v-slot:label>Title</template>
            <b-form-input
              class="single-product-section-input"
              id="input-title"
              type="text"
              v-model="productDetails.title"
              placeholder="Enter Title"
            />
          </b-form-group>
          <b-form-group class="input_label" id="description" label-for="input-description">
            <template v-slot:label>Description</template>
            <b-form-input
              class="single-product-section-input"
              id="input-description"
              type="text"
              v-model="productDetails.description"
              placeholder="Enter Description"
            />
          </b-form-group>
          <b-form-group class="input_label" id="articleCode" label-for="input-articleCode">
            <template v-slot:label>Article Code</template>
            <b-form-input
              class="single-product-section-input"
              id="input-articleCode"
              type="text"
              v-model="productDetails.articleCode"
              placeholder="Enter article code"
            />
          </b-form-group>
          <!-- <b-form-group class="input_label" id="mrp" label-for="input-mrp">
            <template v-slot:label>Mrp</template>
            <b-form-input
              class="single-product-section-input"
              id="input-mrp"
              type="text"
              v-model="productDetails.mrp"
              placeholder="Input Mrp"
            />
          </b-form-group> -->
          <!-- <b-form-group class="input_label" id="discountInPercent" label-for="input-discountInPercent">
            <template v-slot:label>Discount (In Percent)</template>
            <b-form-input
              class="single-product-section-input"
              id="input-discountInPercent"
              type="text"
              v-model="productDetails.discount"
              placeholder="Input Discount"
            />
            <b-form-text id="input-role-help">Note: Fill only one of the discount or offers field.</b-form-text>
          </b-form-group> -->

          <b-form-group class="input_label" id="offers" label-for="input-offer">
            <template v-slot:label>Offers</template>
            <b-form-input
              class="single-product-section-input"
              id="input-offers"
              type="text"
              v-model="productDetails.offers"
              placeholder="Input Offers"
            />
            <b-form-text id="input-role-help">Note: Fill only one of the discount or offers field.</b-form-text>
          </b-form-group>

          <!-- <b-form-group class="input_label" id="sellingPrice" label-for="input-sellingPrice">
            <template v-slot:label>Selling Price</template>
            <b-form-input
              class="single-product-section-input"
              id="input-sellingPrice"
              type="text"
              v-model="productDetails.sellingPrice"
              placeholder="Input Selling Price"
            />
          </b-form-group> -->

          <b-form-group class="input_label" id="tags" label-for="input-tag">
            <template v-slot:label>Category</template>
            <b-form-input
              class="single-product-section-input"
              id="input-tag"
              type="text"
              v-model="productDetails.category"
              placeholder="Input Category"
            />
          </b-form-group>
          <b-form-group class="input_label" id="startDate" label-for="start-datepicker">
            <template v-slot:label>StartDate</template>
            <b-form-datepicker
              id="start-datepicker"
              placeholder="Start Date"
              :min="minDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="productDetails.startDate"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group class="input_label" id="endDate" label-for="end-datepicker">
            <template v-slot:label>EndDate</template>

            <b-form-datepicker
              id="end-datepicker"
              placeholder="End Date"
              :min="minDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="productDetails.endDate"
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group class="input_label" id="group-products" label-for="input-products">
            <template v-slot:label> Region Ids </template>

            <multiselect
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              v-model="productDetails.regionids"
              placeholder="Select region Ids"
              label="text"
              :options="regions"
            >
            </multiselect>
          </b-form-group>

          <b-form-group class="input_label" id="group-products" label-for="input-products">
            <template v-slot:label> Cluster Ids </template>
            <multiselect
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              v-model="productDetails.clusterids"
              placeholder="Select Cluster Ids"
              label="text"
              :options="clusters"
            >
            </multiselect>
          </b-form-group>

          <b-form-group class="input_label" id="group-products" label-for="input-products">
            <template v-slot:label> Store Ids </template>
            <multiselect
              v-model="productDetails.storeids"
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              placeholder="Select Store Ids"
              label="text"
              track-by="value"
              :options="stores"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
            ></multiselect>
            <b-form-text id="input-role-help"
              >Note: The first selected store id will be the primary store id for all operations.</b-form-text
            >
            <b-button class="uploadStoreButton" @click="uploadStores" variant="success">Upload Stores</b-button>
          </b-form-group>

          <b-form-group class="input_label" id="group-products" label-for="input-products">
            <template v-slot:label> Store Format </template>

            <multiselect
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              v-model="productDetails.formats"
              placeholder="Select Store Format"
              label="text"
              :options="formats"
            >
            </multiselect>
          </b-form-group>
          <b-form-group style="text-align: center">
            <!-- <label>Images</label> -->
            <img
              v-for="(image, index) in productDetails.images"
              :src="image"
              :key="index"
              style="height: 200px; width: 200px"
            />
            <input style="margin-left: 90px; margin-top: 12px" type="file" multiple id="selected-images" />
          </b-form-group>

          <b-button block class="mt-2 mb-2 form_btn" v-on:click="onUpdateProduct" v-if="selectedProduct"
            >Update</b-button
          >
          <b-button block class="mt-2 mb-2 form_btn" v-if="selectedProduct" @click="onDeleteProduct">Delete</b-button>
          <b-button block class="mt-2 mb-2 form_btn" variant="success" v-else v-on:click="onAddProduct">Add</b-button>
        </div>
        <div class="productelseimage" v-else><img width="150" src="../../assets/images/images.png"/></div>
      </div>
    </div>
  </b-overlay>
</template>

<style lang="scss" scoped>
.catalogue-section-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gray;
}

.button-section {
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.catalogue-section {
  flex: 1;
  // background-color: #f0f2f5;
  // border-right: 1px solid gray;
  min-width: 300px;
  height: 80vh;
  overflow: auto;
  &:last-child {
    border-right: none;
  }
}

.single-product-section {
  flex: 1;
  // background-color: #f0f2f5;
  border-right: 1px solid gray;
  min-width: 300px;
  padding: 20px;
  height: 80vh;
  overflow: auto;
  &:last-child {
    border-right: none;
  }
}

.product-section {
  flex: 1;
  border: none;
  outline: none;
  // border-right: 1px solid gray;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  min-width: 300px;
  height: 80vh;
  overflow: auto;
  &:last-child {
    border-right: none;
  }
}

.catalogue-title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: bold;
  // color: #333;
}
.catalogue-title-label {
  color: #333;
  font-size: 20px;
  font-weight: 300;
}
.product-title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 12px;
  // color: #333;
}

.catalogue-description {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #666;
}

button:focus {
  outline: 0;
}

.uploadStoreButton {
  padding: 2px 7px 2px 7px;
}

.list-item {
  text-align: center;
  background: none;
  border: none;
  outline: none;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 95%;
  margin: 5px 0px 2px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

// Top Buttons CSS
.top_btn {
  outline: none;
  font-weight: 500;
  color: #f28a3e;
  border-radius: 3px;
  margin-top: 5px;
  background-color: #fff;
  border: 1.5px solid #f28a3e;
}

.top_btn:hover {
  color: #fff;
  background-color: #f28a3e;
}

// Product Section Buttons Styling
.product-list {
  margin: 5px 0px 2px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.checkboxActive {
  width: 20px;
  height: 20px;
  float: left;
  margin-top: 7px;
}

.activeProduct {
  color: green;
  float: right;
  font-size: 25px;
}

.inactiveProduct {
  color: red;
  float: right;
  font-size: 25px;
}

// Input Fields Styling
.input_label {
  font-size: 14px;
  font-weight: 500;
}

.single-product-section-input {
  border-radius: 3px;
  border: 1.5px solid #c4c3d0;
}
.checkboxAll {
  width: 20px;
  height: 20px;
  margin-left: 3.5%;
  margin-top: 4%;
}
.allSelectLabel {
  margin-bottom: 5px;
  margin-left: 2%;
  vertical-align: bottom;
  font-weight: 400;
  font-size: 15px;
}

// Update Button Styling
.form_btn {
  outline: none;
  color: #f28a3e;
  font-weight: 500;
  border-radius: 3px;
  background-color: #fff;
  border: 1.5px solid #f28a3e;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.form_btn:hover {
  color: #fff;
  background-color: #f28a3e;
}
.productText {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 50%;
}
.productelseimage {
  text-align: center;
  margin-top: 35%;
}
</style>

<script>
import Vue from 'vue';
import csv from 'csvtojson';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import Multiselect from 'vue-multiselect';
import configService from '@/services/configService';

const PRODUCT_TEMPLATE = {
  title: '',
  description: '',
  availableQuantity: -1,
  active: false,
  mrp: 0,
  discount: 0,
  offers: '',
  images: [],
  category: '',
  articleCode: '',
  sellingPrice: 0,
  storeids: [],
  regionids: [],
  clusterids: [],
  formats: [],
  startDate: null,
  endDate: null
};

export default {
  name: 'ProductCatalogues',
  components: { Multiselect },
  data() {
    return {
      productDetails: PRODUCT_TEMPLATE,
      isAddingCatalogue: false,
      catalogueTitleText: '',
      catalogues: [],
      newGroup: [],
      newProducts: [],
      storeids: [],
      regionids: [],
      clusterids: [],
      formats: [],
      startDate: null,
      endDate: null,
      dateTimeFormat: configService.get('format').pickerDateTime,
      minDate: moment().format('YYYY-MM-DD'),
      catalogue: '',
      selectedCatalogueLocal: '',
      selectedCatalogue: '',
      selectedProducts: [],
      allSelected: false,
      box: '',
      busy: false,
      timeout: null,
      url: 'https://spencers.ecatalog.shop/product'
    };
  },
  beforeDestroy() {
    this.clearTimeout();
  },
  mounted() {
    this.closeAddProduct();
    this.listProducts({ router });
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('product', ['loadingCatalogues', 'products', 'isProductFormOpen', 'selectedProduct']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList'])
  },
  methods: {
    ...mapActions('product', [
      'closeAddProduct',
      'editProduct',
      'addProduct',
      'deleteProduct',
      'uploadProduct',
      'listProducts',
      'selectProduct',
      'openAddProduct',
      'approveProduct',
      'deleteCatalogue'
    ]),
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),
    ifPermission() {
      const user = this.user.roles.map(role => role.permissions);
      const newUser = user.map(permission => permission.map(permission => permission.name));
      if (newUser.flat(1).find(permission => permission === 'APPROVE_CATALOGUE')) {
        return true;
      }
      if (newUser.flat(1).find(permission => permission === 'MARKETING_APPROVER')) {
        return true;
      }
      return false;
    },
    ifSelecetdProducts() {
      if (this.selectedProducts.length > 0) {
        return true;
      }
      return false;
    },
    select(product) {
      this.allSelected = false;
      const productId = product._id; // eslint-disable-line
      if (this.selectedProducts.includes(productId)) {
        this.selectedProducts = this.selectedProducts.filter(i => i !== productId);
      } else {
        this.selectedProducts = [...this.selectedProducts, product._id]; // eslint-disable-line no-underscore-dangle
      }
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedProducts = [];
        this.allSelected = false;
      } else {
        this.selectedProducts = this.newProducts.map(product => product._id); // eslint-disable-line no-underscore-dangle
        this.allSelected = true;
      }
    },
    local(catalogueLocal) {
      this.selectedCatalogueLocal = catalogueLocal;
    },

    getProducts(catalogue) {
      this.newProducts = this.products.filter(product => product._id === catalogue)[0].products; // eslint-disable-line
      // this.newProducts = this.newProducts.map(product => product.title);
      this.selectedCatalogue = catalogue;
    },
    onClickAddProduct() {
      this.openAddProduct();
      this.selectProduct({ product: null });
      this.productDetails = PRODUCT_TEMPLATE;
    },
    onSelectProduct(product) {
      this.selectProduct({ product: product._id }); // eslint-disable-line
      this.productDetails = {
        ...product,
        active: product.active,
        category: product.category.join(','),
        regionids: product.regions.map(region => ({ text: region, value: region })),
        storeids: product.storeids.map(store => ({ text: store, value: store })),
        clusterids: product.clusters.map(cluster => ({ text: cluster, value: cluster })),
        formats: product.formats.map(format => ({ text: format, value: format }))
      };
    },
    onClickPreview() {
      window.open('https://spencers.ecatalog.shop/product');
    },
    selectCata(selectedCatalogue) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',

        preConfirm: () => {
          this.deleteCatalogue({
            catalogueName: selectedCatalogue.replace(/[&\/\\]/g, '%26'), // eslint-disable-line
            sort: true
          });

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });
    },
    onAddCatalogue() {
      this.isAddingCatalogue = true;
      this.isEditingCatalogue = false;
      this.catalogueTitleText = '';
      this.$nextTick(() => {
        const textInput = document.getElementById('add-catalogue-title-text');
        if (textInput) {
          textInput.focus();
        }
      });
    },
    onCloseAddCatalogue() {
      this.isAddingCatalogue = false;
      this.catalogueTitleText = '';
    },
    onSaveNewCatalogue(catalogueTitleText) {
      const catalogues = { ...this.catalogues };
      catalogues[catalogueTitleText] = catalogueTitleText;
      this.catalogues = catalogues;
      this.onCloseAddCatalogue();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async onAddProduct() {
      const fileSelector = document.getElementById('selected-images');
      const { files } = fileSelector;
      let filesInBase64 = Array.from(files).map(file => this.getBase64(file));
      filesInBase64 = await Promise.all(filesInBase64);

      const storeids = [];
      this.productDetails.storeids.map(({ value }) => storeids.push(value));

      const regions = [];
      this.productDetails.regionids.map(({ value }) => regions.push(value));

      const clusters = [];
      this.productDetails.clusterids.map(({ value }) => clusters.push(value));

      const formats = [];
      this.productDetails.formats.map(({ value }) => formats.push(value));

      let catalogueGroup = null;
      if (this.selectedCatalogueLocal) {
        catalogueGroup = this.selectedCatalogueLocal;
      } else {
        catalogueGroup = this.selectedCatalogue;
      }

      const {
        title,
        description,
        availableQuantity,
        mrp,
        active,
        category,
        discount,
        discountInPercent,
        offers,
        articleCode,
        sellingPrice,
        startDate,
        endDate
      } = this.productDetails;

      const params = {
        title,
        description,
        discountInPercent,
        offers,
        availableQuantity,
        mrp,
        active,
        discount,
        articleCode,
        sellingPrice,
        storeids,
        regions,
        clusters,
        formats,
        startDate,
        endDate,
        catalogueGroup,
        category: category.split(',').map(i => i.trim()),
        images: filesInBase64
      };
      this.addProduct({ params, router });
      setTimeout(() => {
        this.closeAddProduct();
      }, 1000);
      if (this.selectedCatalogueLocal) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
    async onUpdateProduct() {
      const fileSelector = document.getElementById('selected-images');
      const { files } = fileSelector;
      let filesInBase64 = Array.from(files).map(file => this.getBase64(file));
      filesInBase64 = await Promise.all(filesInBase64);

      const storeids = [];
      this.productDetails.storeids.map(({ value }) => storeids.push(value));

      const regions = [];
      this.productDetails.regionids.map(({ value }) => regions.push(value));

      const clusters = [];
      this.productDetails.clusterids.map(({ value }) => clusters.push(value));

      const formats = [];
      this.productDetails.formats.map(({ value }) => formats.push(value));

      let catalogueGroup = null;
      catalogueGroup = this.selectedCatalogue;

      const {
        title,
        description,
        availableQuantity,
        mrp,
        offers,
        discount,
        category,
        articleCode,
        sellingPrice,
        startDate,
        endDate,
        active
      } = this.productDetails;

      const params = {
        title,
        description,
        offers,
        availableQuantity,
        mrp,
        articleCode,
        sellingPrice,
        storeids,
        regions,
        clusters,
        formats,
        discount,
        startDate,
        endDate,
        catalogueGroup,
        active,
        category: category.split(',').map(i => i.trim()),
        images: filesInBase64
      };

      this.editProduct({ params, productId: this.selectedProduct, router });
      setTimeout(() => {
        this.closeAddProduct();
      }, 1000);
    },
    async onDeleteProduct() {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',

        preConfirm: () => {
          this.deleteProduct({
            productId: this.selectedProduct,
            sort: true
          });
        }
      });
      setTimeout(() => {
        this.closeAddProduct();
      }, 1000);

      if (this.selectedCatalogue === '') {
        window.location.reload();
      }
    },
    showButtons() {
      if (this.selectedCatalogue !== '') {
        return true;
      }
      if (this.selectedCatalogueLocal !== '') {
        return true;
      }
      return false;
    },
    async onClickCopy(url) {
      await navigator.clipboard.writeText(url);
      Vue.swal({
        title: 'Copied!',
        text: 'The link has been copied to your clipboard.',
        icon: 'success',
        timer: 2000,
        timerProgressBar: true
      });
    },
    createCsv() {
      const csv = `Title,Description,Article Code,Category,Images,Offers,Store Ids,Regions,Clusters,Formats,startDate(MM-DD-YYYY),endDate(MM-DD-YYYY)\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'productFile.csv';
      anchor.click();
    },
    async onClickUploadProduct() {
      await Vue.swal({
        title: 'Upload file for Products..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Products file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (this.selectedCatalogueLocal) {
                    const storeids = item[6] ? item[6].split(',') : [];
                    const regions = item[7] ? item[7].split(',') : [];
                    const clusters = item[8] ? item[8].split(',') : [];
                    const formats = item[9] ? item[9].split(',') : [];
                    const category = item[3] ? item[3].split(',') : [];

                    const storesCap = storeids.map(
                      store => store.charAt(0).toUpperCase() + store.slice(1).toLowerCase()
                    );
                    const regionsCap = regions.map(
                      region => region.charAt(0).toUpperCase() + region.slice(1).toLowerCase()
                    );
                    const clustersCap = clusters.map(cluster => {
                      const newCluster = cluster.toLowerCase();
                      if (newCluster === 'ncr') {
                        return 'NCR';
                      }
                      if (newCluster === 'tnkbw') {
                        return 'TNKBW';
                      }
                      if (newCluster === 'east up') {
                        return 'East UP';
                      }
                      if (newCluster === 'cap') {
                        return 'CAP';
                      }
                      return cluster.charAt(0).toUpperCase() + cluster.slice(1).toLowerCase();
                    });
                    const formatsCap = formats.map(
                      format => format.charAt(0).toUpperCase() + format.slice(1).toLowerCase()
                    );

                    const categoryCap = category.map(cat => cat.toUpperCase());
                    const values = item.reduce((acc, curr, index) => {
                      if (index === 0) {
                        acc.title = curr;
                      }
                      if (index === 1) {
                        acc.description = curr;
                      }
                      if (index === 2) {
                        acc.articleCode = curr;
                      }
                      if (index === 3) {
                        acc.category = categoryCap;
                      }
                      if (index === 4) {
                        acc.images = curr ? curr.split(',') : [];
                      }
                      // if (index === 5) {
                      //   acc.mrp = curr;
                      // }
                      // if (index === 6) {
                      //   acc.discount = curr;
                      // }
                      if (index === 5) {
                        acc.offers = curr;
                      }
                      // if (index === 8) {
                      //   acc.sellingPrice = curr;
                      // }
                      if (index === 6) {
                        if (storesCap.length >= 1) {
                          for (let i = 1; i < storesCap.length; i += 1) {
                            const checkStoreids = this.newStoreList.filter(store => store.storeid === storesCap[i]);
                            if (checkStoreids.length === 0) {
                              Vue.swal({
                                title: 'Invalid Store',
                                text: `Store ${storesCap[i]} is not found in the database! please eneter the correct store`,
                                type: 'error',
                                confirmButtonText: 'Ok'
                              });
                              return null;
                            }
                          }
                        }
                        acc.storeids = storesCap;
                      }
                      if (index === 7) {
                        if (regionsCap.length >= 1) {
                          for (let i = 0; i < regionsCap.length; i += 1) {
                            const checkInDatabse = this.newRegions.filter(region => region.region === regionsCap[i]);
                            if (checkInDatabse.length === 0) {
                              Vue.swal({
                                title: 'Invalid Region',
                                text: `Region ${regionsCap[i]} is not found in the database! please enter the correct region`,
                                type: 'error',
                                confirmButtonText: 'Ok'
                              });
                              return null;
                            }
                          }
                        }
                        acc.regions = regionsCap;
                      }
                      if (index === 8) {
                        if (clustersCap.length >= 1) {
                          for (let i = 0; i < clustersCap.length; i += 1) {
                            const checkClusters = this.newClusters.filter(cluster => cluster.name === clustersCap[i]);
                            if (checkClusters.length === 0) {
                              Vue.swal({
                                title: 'Invalid Cluster',
                                text: `Cluster ${clustersCap[i]} is not found in databse! please enter the correct cluster.`,
                                type: 'error',
                                confirmButtonText: 'Ok'
                              });
                              return null;
                            }
                          }
                        }
                        acc.clusters = clustersCap;
                      }
                      if (index === 9) {
                        if (formatsCap.length >= 1) {
                          for (let i = 0; i < formatsCap.length; i += 1) {
                            const checkFormats = this.newFormats.filter(format => format.format === formatsCap[i]);
                            if (checkFormats.length === 0) {
                              Vue.swal({
                                title: 'Invalid Format',
                                text: `Format ${formatsCap[i]} is not found in databse! please enter the correct format.`,
                                type: 'error',
                                confirmButtonText: 'Ok'
                              });
                              return null;
                            }
                          }
                        }
                        acc.formats = formatsCap;
                      }
                      if (index === 10) {
                        acc.startDate = moment(curr).format('YYYY-MM-DD');
                      }
                      if (index === 11) {
                        acc.endDate = moment(curr).format('YYYY-MM-DD');
                      }
                      acc.mrp = 0;
                      acc.discount = 0;
                      acc.sellingPrice = 0;
                      acc.catalogueGroup = this.selectedCatalogueLocal;
                      return acc;
                    }, {});
                    return values;
                  }

                  const storeids = item[6] ? item[6].split(',') : [];
                  const regions = item[7] ? item[7].split(',') : [];
                  const clusters = item[8] ? item[8].split(',') : [];
                  const formats = item[9] ? item[9].split(',') : [];
                  const category = item[3] ? item[3].split(',') : [];

                  const storesCap = storeids.map(store => store.charAt(0).toUpperCase() + store.slice(1).toLowerCase());
                  const regionsCap = regions.map(
                    region => region.charAt(0).toUpperCase() + region.slice(1).toLowerCase()
                  );
                  const clustersCap = clusters.map(cluster => {
                    const newCluster = cluster.toLowerCase();
                    if (newCluster === 'ncr') {
                      return 'NCR';
                    }
                    if (newCluster === 'tnkbw') {
                      return 'TNKBW';
                    }
                    if (newCluster === 'east up') {
                      return 'East UP';
                    }
                    if (newCluster === 'cap') {
                      return 'CAP';
                    }
                    return cluster.charAt(0).toUpperCase() + cluster.slice(1).toLowerCase();
                  });
                  const formatsCap = formats.map(
                    format => format.charAt(0).toUpperCase() + format.slice(1).toLowerCase()
                  );
                  const categoryCap = category.map(cat => cat.toUpperCase());

                  const values = item.reduce((acc, curr, index) => {
                    if (index === 0) {
                      acc.title = curr;
                    }
                    if (index === 1) {
                      acc.description = curr;
                    }
                    if (index === 2) {
                      acc.articleCode = curr;
                    }
                    if (index === 3) {
                      acc.category = categoryCap;
                    }
                    if (index === 4) {
                      acc.images = curr ? curr.split(',') : [];
                    }
                    // if (index === 5) {
                    //   acc.mrp = curr;
                    // }
                    // if (index === 6) {
                    //   acc.discount = curr;
                    // }
                    if (index === 5) {
                      acc.offers = curr;
                    }
                    // if (index === 8) {
                    //   acc.sellingPrice = curr;
                    // }
                    if (index === 6) {
                      if (storesCap.length >= 1) {
                        for (let i = 1; i < storesCap.length; i += 1) {
                          const checkStoreids = this.newStoreList.filter(store => store.storeid === storesCap[i]);
                          if (checkStoreids.length === 0) {
                            Vue.swal({
                              title: 'Invalid Store',
                              text: `Store ${storesCap[i]} is not found in the database! please eneter the correct store`,
                              type: 'error',
                              confirmButtonText: 'Ok'
                            });
                            return null;
                          }
                        }
                      }
                      acc.storeids = storesCap;
                    }
                    if (index === 7) {
                      if (regionsCap.length >= 1) {
                        for (let i = 0; i < regionsCap.length; i += 1) {
                          const checkInDatabse = this.newRegions.filter(region => region.region === regionsCap[i]);
                          if (checkInDatabse.length === 0) {
                            Vue.swal({
                              title: 'Invalid Region',
                              text: `Region ${regionsCap[i]} is not found in the database! please enter the correct region`,
                              type: 'error',
                              confirmButtonText: 'Ok'
                            });
                            return null;
                          }
                        }
                      }
                      acc.regions = regionsCap;
                    }
                    if (index === 8) {
                      if (clustersCap.length >= 1) {
                        for (let i = 0; i < clustersCap.length; i += 1) {
                          const checkClusters = this.newClusters.filter(cluster => cluster.name === clustersCap[i]);
                          if (checkClusters.length === 0) {
                            Vue.swal({
                              title: 'Invalid Cluster',
                              text: `Cluster ${clustersCap[i]} is not found in databse! please enter the correct cluster.`,
                              type: 'error',
                              confirmButtonText: 'Ok'
                            });
                            return null;
                          }
                        }
                      }
                      acc.clusters = clustersCap;
                    }
                    if (index === 9) {
                      if (formatsCap.length >= 1) {
                        for (let i = 0; i < formatsCap.length; i += 1) {
                          const checkFormats = this.newFormats.filter(format => format.format === formatsCap[i]);
                          if (checkFormats.length === 0) {
                            Vue.swal({
                              title: 'Invalid Format',
                              text: `Format ${formatsCap[i]} is not found in databse! please enter the correct format.`,
                              type: 'error',
                              confirmButtonText: 'Ok'
                            });
                            return null;
                          }
                        }
                      }
                      acc.formats = formatsCap;
                    }
                    if (index === 10) {
                      acc.startDate = moment(curr).format('YYYY-MM-DD');
                    }
                    if (index === 11) {
                      acc.endDate = moment(curr).format('YYYY-MM-DD');
                    }
                    acc.mrp = 0;
                    acc.discount = 0;
                    acc.sellingPrice = 0;
                    acc.catalogueGroup = this.selectedCatalogue;
                    return acc;
                  }, {});
                  return values;
                });
                this.uploadProduct({ router, data: inputs });
                setTimeout(() => {
                  this.closeAddProduct();
                }, 4000);
                if (this.selectedCatalogueLocal) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 6000);
                }
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    createCsv2() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv2();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${item[0].charAt(0).toUpperCase() +
                        item[0].slice(1)} is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.productDetails.storeids.filter(store =>
                  newAddingStore.includes(store.value)
                );
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already there!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.productDetails.storeids = [...this.productDetails.storeids, ...this.abc];
                return null;
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
    onClickApprove() {
      Vue.swal({
        title: 'Are you sure?',
        text: 'You want to publish the selected products.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',

        preConfirm: () => {
          this.approveProduct({
            productIds: this.selectedProducts,
            active: true
          });
          this.showMsgBox();
          this.busy = true;
        }
      });
    },
    onClickDisApprove() {
      Vue.swal({
        title: 'Are you sure?',
        text: 'You want to unpublish the selected products.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        preConfirm: () => {
          this.approveProduct({
            productIds: this.selectedProducts,
            active: false
          });
          this.showMsgBox2();
          this.busy = true;
        }
      });
    },
    showMsgBox() {
      this.box = '';
      setTimeout(
        () =>
          this.$bvModal
            .msgBoxOk('Products are approved  successfully.', {
              title: 'Notice',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            .then(value => {
              this.box = value;
              return window.location.reload();
            }),
        2000
      );
    },
    showMsgBox2() {
      this.box = '';
      setTimeout(
        () =>
          this.$bvModal
            .msgBoxOk('Products are disapproved successfully.', {
              title: 'Notice',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            .then(value => {
              this.box = value;
              return window.location.reload();
            }),
        2000
      );
    },
    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
    setTimeout(callback) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.clearTimeout();
        callback();
      }, 2000);
    }
  },
  watch: {
    products(newValue) {
      this.newGroup = newValue.map(i => i._id); // eslint-disable-line
      if (this.selectedCatalogue) {
        const temp = newValue.filter(product => product._id === this.selectedCatalogue); // eslint-disable-line
        if (temp.length) {
          this.newProducts = temp[0].products;
        } else {
          this.newProducts = [];
        }
      }
    },
    clusterList(newValue) {
      let comparingClusters = [];
      comparingClusters = newValue.map(cluster => ({
        name: cluster.value
      }));
      this.newClusters = comparingClusters;
      this.clusters = newValue;
    },
    regionList(newValue) {
      let comparingRegions = [];
      comparingRegions = newValue.map(region => ({
        region: region.value
      }));
      this.newRegions = comparingRegions;
      this.regions = newValue;
    },
    formatList(newValue) {
      let comparingFormats = [];
      comparingFormats = newValue.map(format => ({
        format: format.value
      }));
      this.newFormats = comparingFormats;
      this.formats = newValue;
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.value
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue;
    },
    catalogueList(newValue) {
      this.catalogues = newValue;
    }
  }
};
</script>

<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
