<template>
  <b-overlay :show="busy" rounded="sm">
    <div class="catalogue-section-container">
      <div class="catalogue-section">
        <!-- <div v-if="loadingCatalogues">Loading ...</div> -->
        <div>
          <div class="button-section">
            <span>
              <b-button class="ml-2 top_btn" size="sm" @click="onAddCategory">
                <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" /> Add Category
              </b-button>
            </span>
            <!-- <span>
              <b-button class="ml-2 top_btn" size="sm" variant="secondary" @click="onClickPreview">
                <font-awesome-icon :icon="['fas', 'eye']" class="mr-1" />
                Customer View</b-button
              >
            </span> -->
            <span v-if="ifPermission()">
              <b-button class="ml-2 top_btn" size="sm" variant="secondary" @click="onClickCopy(url)">
                <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />
                Customer Link</b-button
              >
            </span>
            <span>
              <b-button
                v-if="selectedCategory"
                class="ml-2 top_btn"
                size="sm"
                variant="secondary"
                @click="selectCata(selectedCategory)"
              >
                <font-awesome-icon :icon="['fas', 'trash-alt']" class="mr-1" />
                Delete</b-button
              ></span
            >
            <span>
              <b-button class="ml-2 mt-2" size="sm" variant="success" @click="downloadCategory()">
                <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Download Category
              </b-button>
            </span>
            <span>
              <b-button class="ml-2 mt-2" size="sm" variant="success" @click="onClickDirectUploadProduct()">
                <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Direct Upload
              </b-button>
            </span>
          </div>
          <div v-if="isAddingCategory" class="list-item catalogue-title">
            <label class="catalogue-title-label">Add New Category</label>
            <br />
            <input
              type="text"
              class="catalogue-title-input"
              v-model="addNewCategory.name"
              placeholder="Enter Category Name"
            />
            <br />
            <input
              type="text"
              class="catalogue-title-input"
              v-model="addNewCategory.imageUrl"
              placeholder="Enter Image Url"
            />
            <div>
              <b-button class="ml-2 top_btn" size="ml" variant="success" @click="postCategory()">Save</b-button>
              <b-button class="ml-2 top_btn" size="ml" variant="fail" @click="cancelCategory()">Cancel</b-button>
            </div>
          </div>
          <div v-if="categories.length">
            <div v-for="category in categories" :key="category.id">
              <button
                class="list-item catalogue-title"
                @click="() => getCategories(category)"
                :style="
                  `background: ${selectedCategory._id === category._id ? '#f28a3e' : 'none'}; color: ${
                    selectedCategory._id === category._id ? '#fff' : '#42526E'
                  };`
                "
              >
                {{ category.name }}
              </button>
            </div>
          </div>
          <div v-else>
            <div>
              <label class="catalogue-title-label">No Category Found . Please Add New Category.</label>
            </div>
          </div>
        </div>
      </div>
      <div class="product-section">
        <div v-if="selectedCategory">
          <div class="button-section">
            <span v-if="selectedCategory">
              <b-button class="ml-2 top_btn" size="sm" @click="onAddSubCategory">
                <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Add SubCategory</b-button
              >
            </span>
            <span>
              <b-button
                v-if="selectedSubCategory"
                class="ml-2 top_btn"
                size="sm"
                variant="secondary"
                @click="selectSubCata(selectedSubCategory)"
              >
                <font-awesome-icon :icon="['fas', 'trash-alt']" class="mr-1" />
                Delete</b-button
              >
            </span>
          </div>
          <div v-if="isAddingSubCategory" class="list-item catalogue-title">
            <label class="catalogue-title-label">Add New SubCategory</label>
            <br />
            <input
              type="text"
              class="catalogue-title-input"
              v-model="addNewSubCategory.name"
              placeholder="Enter SubCategory Name"
            />
            <br />
            <input
              type="text"
              class="catalogue-title-input"
              v-model="addNewSubCategory.imageUrl"
              placeholder="Enter Image Url"
            />
            <div>
              <b-button class="ml-2 top_btn" size="ml" variant="success" @click="postSubCategory()">Save</b-button>
              <b-button class="ml-2 top_btn" size="ml" variant="fail" @click="cancelSubCategory()">Cancel</b-button>
            </div>
          </div>
          <div v-for="subCategory in subCategories" :key="subCategory.id">
            <button
              class="list-item catalogue-title"
              @click="() => getSubCategories(subCategory)"
              :style="
                `background: ${selectedSubCategory._id === subCategory._id ? '#f28a3e' : 'none'}; color: ${
                  selectedSubCategory._id === subCategory._id ? '#fff' : '#42526E'
                };`
              "
            >
              {{ subCategory.name }}
            </button>
          </div>
        </div>
        <div v-else>
          <div :style="`margin: 20px`">
            <label class="productText">Please Select a Category.</label>
          </div>
        </div>
      </div>
      <div v-if="ifPermission()" class="single-product-section">
        <div>
          <div v-if="showButtons()">
            <div class="button-section">
              <span>
                <b-button
                  :disabled="ifSelectedProducts() ? false : true"
                  class="ml-2 top_btn"
                  size="sm"
                  variant="primary"
                  @click="onClickApprove"
                >
                  <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Approve Products
                </b-button>
              </span>
              <span>
                <b-button
                  :disabled="ifSelectedProducts() ? false : true"
                  class="ml-2 top_btn"
                  size="sm"
                  variant="success"
                  @click="onClickDisApprove"
                >
                  <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Disapprove Products
                </b-button>
              </span>
              <div style="margin-bottom: -8px; margin-top: -5px">
                <input :checked="allSelected" @click="selectAll()" class="checkboxAll" type="checkbox" id="scales" />
                <label class="allSelectLabel" for="scales">Select All Products</label>
              </div>
            </div>
            <button
              v-for="product in newProducts"
              :key="product._id"
              class="list-item product-list"
              @click="() => onSelectProduct(product)"
              :style="
                `background: ${selectProduct._id === product._id ? '#f28a3e' : 'none'}; color: ${
                  selectProduct._id === product._id ? '#fff' : '#42526E'
                };`
              "
            >
              <input
                class="checkboxActive"
                :checked="selectedProducts.includes(product._id)"
                @click="select(product)"
                type="checkbox"
              />
              <span v-b-modal.modal-1 class="product-title">
                {{ product.description }}
              </span>
              <span v-b-tooltip.hover title="Active" v-if="product.active === true" class="activeProduct">●</span>
              <span v-b-tooltip.hover title="Inactive" v-if="product.active === false" class="inactiveProduct">●</span>
            </button>
          </div>
          <div class="productText" v-else> Please select a category & subcategory to view products </div>
        </div>
      </div>
      <div v-else class="single-product-section">
        <div>
          <div v-if="showButtons()">
            <div class="button-section">
              <span>
                <b-button v-if="!selectProduct" v-b-modal.modal-1 class="ml-2 top_btn" size="sm" variant="primary">
                  <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" @click="onAddNewProduct()" />Add New Product
                </b-button>
              </span>
              <span>
                <b-button class="ml-2 top_btn" size="sm" variant="success" @click="onClickUploadProduct()">
                  <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload
                </b-button>
              </span>
            </div>
          </div>
          <div v-if="selectedSubCategory">
            <button
              v-b-modal.modal-1
              v-for="product in newProducts"
              :key="product._id"
              class="list-item product-list"
              @click="() => onSelectProduct(product)"
              :style="
                `background: ${selectProduct._id === product._id ? '#f28a3e' : 'none'}; color: ${
                  selectProduct._id === product._id ? '#fff' : '#42526E'
                };`
              "
            >
              {{ product.description }}
            </button>
          </div>
        </div>
        <div v-if="!selectedSubCategory" class="productText">
          Please select a category & subcategory to view products
        </div>
      </div>
      <b-modal id="modal-1" ref="modalProduct" title="Edit Product Details" hide-footer scrollable static size="lg">
        <div>
          <b-form-group class="input_label" id="title" label-for="input-title">
            <template v-slot:label>Title</template>
            <b-form-input
              class="single-product-section-input"
              id="input-title"
              v-model="productDetails.title"
              type="text"
              placeholder="Enter Title"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="input_label" id="description" label-for="input-description">
            <template v-slot:label>Description</template>
            <b-form-input
              class="single-product-section-input"
              id="input-description"
              v-model="productDetails.description"
              type="text"
              placeholder="Enter Description"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="input_label" id="articleCode" label-for="input-articleCode">
            <template v-slot:label>Article Code</template>
            <b-form-input
              class="single-product-section-input"
              id="input-articleCode"
              v-model="productDetails.articleCode"
              type="text"
              placeholder="Enter Article Code"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="input_label" id="offers" label-for="input-offers">
            <template v-slot:label>Offers</template>
            <b-form-input
              class="single-product-section-input"
              id="input-offers"
              v-model="productDetails.offers"
              type="text"
              placeholder="Enter Offers"
            ></b-form-input>
            <b-form-text id="input-role-help">Note: Fill only one of the discount or offers field.</b-form-text>
          </b-form-group>
          <b-form-group class="input_label" id="category" label-for="input-category">
            <template v-slot:label>Category</template>
            <b-form-input
              :disabled="true"
              class="single-product-section-input"
              id="input-category"
              v-model="productDetails.category"
              type="text"
              placeholder="Enter Category"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="input_label" id="subCategory" label-for="input-subCategory">
            <template v-slot:label>Sub Category</template>
            <b-form-input
              :disabled="true"
              class="single-product-section-input"
              id="input-subCategory"
              v-model="productDetails.subCategory"
              type="text"
              placeholder="Enter Sub Category"
            ></b-form-input>
          </b-form-group>
          <b-form-group class="input_label" id="startDate" label-for="start-datepicker">
            <template v-slot:label>Start Date</template>
            <b-form-datepicker
              id="start-datepicker"
              placeholder="Select Start Date"
              :min="minDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              v-model="productDetails.startDate"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group class="input_label" id="endDate" label-for="end-datepicker">
            <template v-slot:label>End Date</template>
            <b-form-datepicker
              id="end-datepicker"
              placeholder="Select End Date"
              :min="minDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              v-model="productDetails.endDate"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group class="input_label" id="regions" label-for="input-regions">
            <template v-slot:label> Region Ids </template>

            <multiselect
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              v-model="productDetails.regionids"
              :options="regions"
              placeholder="Select Region Ids"
              label="text"
            >
            </multiselect>
          </b-form-group>
          <b-form-group class="input_label" id="clusters" label-for="input-clusters">
            <template v-slot:label> Cluster Ids </template>
            <multiselect
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              v-model="productDetails.clusterids"
              :options="clusters"
              placeholder="Select Cluster Ids"
              label="text"
            >
            </multiselect>
          </b-form-group>
          <b-form-group class="input_label" id="stores" label-for="input-stores">
            <template v-slot:label> Store Ids </template>
            <multiselect
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              v-model="productDetails.storeids"
              :options="stores"
              placeholder="Select Store Ids"
              label="text"
            >
            </multiselect>
            <b-form-text id="input-role-help"
              >Note: The first selected store id will be the primary store id for all operations.</b-form-text
            >
            <b-button class="uploadStoreButton" @click="uploadStores" variant="success">Upload Stores</b-button>
          </b-form-group>
          <b-form-group class="input_label" id="formats" label-for="input-formats">
            <template v-slot:label> Formats </template>
            <multiselect
              ref="multiselect"
              tag-placeholder="Add this as new tag"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              v-model="productDetails.formats"
              :options="formats"
              placeholder="Select Formats"
              label="text"
            >
            </multiselect>
          </b-form-group>
          <b-form-group style="text-align: center">
            <img :src="productDetails.images" style="height: 200px; width: 200px" />
          </b-form-group>
          <b-form-group class="input_label" id="images" label-for="input-images">
            <template v-slot:label>Images</template>
            <b-form-input
              class="single-product-section-input"
              id="input-images"
              v-model="productDetails.images"
              type="text"
              placeholder="Enter Images"
            ></b-form-input>
          </b-form-group>
          <b-button
            variant="danger"
            class="mt-2 mb-2 mr-3 float-right form_btn"
            v-if="selectProduct"
            @click="onDeleteProduct()"
            >Delete</b-button
          >
          <b-button
            variant="success"
            class="mt-2 mb-2 mr-3 form_btn float-right"
            @click="onUpdateProduct()"
            v-if="selectProduct"
            >Update</b-button
          >
          <b-button block class="mt-2 mb-2 form_btn" variant="success" v-else @click="onAddProduct()">ADD</b-button>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import Vue from 'vue';
import csv from 'csvtojson';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import moment from 'moment';
import Multiselect from 'vue-multiselect';

const PRODUCT_TEMPLATE = {
  title: '',
  active: false,
  description: '',
  articleCode: '',
  mrp: 0,
  discount: 0,
  offers: '',
  sellingPrice: 0,
  images: '',
  category: '',
  subCategory: '',
  startDate: null,
  endDate: null,
  storeids: [],
  regionids: [],
  clusterids: [],
  formats: []
};

export default {
  name: 'ProductAddInCatalogue',
  components: {
    Multiselect
  },
  data() {
    return {
      productDetails: PRODUCT_TEMPLATE,
      categories: [],
      subCategories: [],
      selectedCategory: '',
      selectedSubCategory: '',
      newProducts: [],
      selectProduct: '',
      selectedProducts: [],
      allSelected: false,
      addNewCategory: {
        name: '',
        type: '1',
        imageUrl: ''
      },
      addNewSubCategory: {
        name: '',
        type: '2',
        imageUrl: '',
        parent: ''
      },
      isAddingCategory: false,
      isAddingSubCategory: false,
      box: '',
      busy: false,
      timeout: null,
      url: 'https://spencers.ecatalog.shop/categoryList',
      minDate: moment().format('YYYY-MM-DD'),
      startDate: null,
      endDate: null,
      storeids: [],
      regionids: [],
      clusterids: [],
      formats: [],
      stores: [],
      regions: [],
      clusters: []
    };
  },
  mounted() {
    this.listCategory({ router });
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('product', ['categoryList', 'productsWithCategory']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList'])
  },
  methods: {
    ...mapActions('product', [
      'listCategory',
      'addCategory',
      'editCategory',
      'deleteCategory',
      'listProductsCategory',
      'approveProduct',
      'editProduct',
      'deleteProduct',
      'addProduct',
      'uploadProduct'
    ]),
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),
    downloadCategory() {
      let csv = `Category,Subcategory\n`;
      const category = this.categoryList.filter(i => i.type === '1');
      const subCategory = this.categoryList.filter(i => i.type === '2');

      category.forEach(row => {
        csv += `${row.name}`;
        subCategory.forEach(item => {
          if (row.name === item.parent) {
            csv += `,"${item.name}"\n`;
          }
        });
      });

      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Category & Sub-Category';
      anchor.click();
    },
    onClickPreview() {
      // window.open('https://spencers.ecatalog.shop/categoryList');
      router.push('/categoryList');
    },
    ifPermission() {
      const user = this.user.roles.map(role => role.permissions);
      const newUser = user.map(permission => permission.map(permission => permission.name));
      if (newUser.flat(1).find(permission => permission === 'APPROVE_CATALOGUE')) {
        return true;
      }
      if (newUser.flat(1).find(permission => permission === 'MARKETING_APPROVER')) {
        return true;
      }
      return false;
    },
    async onClickCopy(url) {
      await navigator.clipboard.writeText(url);
      Vue.swal({
        title: 'Copied!',
        text: 'The link has been copied to your clipboard.',
        icon: 'success',
        timer: 2000,
        timerProgressBar: true
      });
    },
    selectCata(selectedCategory) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        preConfirm: () => {
          this.deleteCategory({
            categoryId: selectedCategory._id // eslint-disable-line
          });
        }
      });
    },
    selectSubCata(selectedSubCategory) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        preConfirm: () => {
          this.deleteCategory({
            categoryId: selectedSubCategory._id // eslint-disable-line
          });
        }
      });
      this.selectedSubCategory = '';
    },
    getCategories(category) {
      this.selectedCategory = category;
      this.selectedSubCategory = '';
      this.subCategories = this.categoryList.filter(i => i.parent === category.name && i.type === '2');
    },
    getSubCategories(subCategory) {
      this.selectedSubCategory = subCategory;
      this.listProductsCategory({
        query: {
          category: subCategory.parent.replaceAll('&', '%26'),
          subCategory: subCategory.name.replaceAll('&', '%26')
        }
      });
      this.productDetails = {
        ...PRODUCT_TEMPLATE,
        category: subCategory.parent,
        subCategory: subCategory.name
      };
      this.selectProduct = '';
    },
    onAddCategory() {
      this.isAddingCategory = true;
    },
    onAddSubCategory() {
      this.isAddingSubCategory = true;
    },
    postCategory() {
      this.addCategory({
        params: {
          name: this.addNewCategory.name,
          type: this.addNewCategory.type,
          imageUrl: this.addNewCategory.imageUrl
        }
      });
      this.isAddingCategory = false;
      this.addNewCategory = {
        name: '',
        type: '1',
        imageUrl: ''
      };
    },
    cancelCategory() {
      this.isAddingCategory = false;
    },
    postSubCategory() {
      this.addCategory({
        params: {
          name: this.addNewSubCategory.name,
          type: this.addNewSubCategory.type,
          imageUrl: this.addNewSubCategory.imageUrl,
          parent: this.selectedCategory.name
        }
      });
      this.isAddingSubCategory = false;
      this.addNewSubCategory = {
        name: '',
        type: '2',
        imageUrl: '',
        parent: ''
      };
    },
    cancelSubCategory() {
      this.isAddingSubCategory = false;
    },
    showButtons() {
      if (this.selectedSubCategory) {
        return true;
      }
      return false;
    },
    onSelectProduct(product) {
      this.selectProduct = product;
      this.productDetails = {
        ...product,
        regionids: product.regions.map(region => ({ text: region, value: region })),
        storeids: product.storeids.map(store => ({ text: store, value: store })),
        clusterids: product.clusters.map(cluster => ({ text: cluster, value: cluster })),
        formats: product.formats.map(format => ({ text: format, value: format }))
      };
    },
    select(product) {
      this.allSelected = false;
      const productId = product._id; // eslint-disable-line
      if (this.selectedProducts.includes(productId)) {
        this.selectedProducts = this.selectedProducts.filter(i => i !== productId);
      } else {
        this.selectedProducts = [...this.selectedProducts, productId]; // eslint-disable-line no-underscore-dangle
      }
    },
    ifSelectedProducts() {
      if (this.selectedProducts.length > 0) {
        return true;
      }
      return false;
    },
    onClickApprove() {
      Vue.swal({
        title: 'Are you sure?',
        text: 'You want to publish the selected products.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        preConfirm: () => {
          this.approveProduct({
            productIds: this.selectedProducts,
            active: true,
            params: {
              category: this.selectedCategory.parent,
              subCategory: this.selectedCategory.name
            }
          });
          this.selectedSubCategory = '';
          this.selectedProducts = [];
          this.allSelected = false;
        }
      });
    },
    onClickDisApprove() {
      Vue.swal({
        title: 'Are you sure?',
        text: 'You want to unpublish the selected products.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        preConfirm: () => {
          this.approveProduct({
            productIds: this.selectedProducts,
            active: false,
            params: {
              category: this.selectedCategory.parent,
              subCategory: this.selectedCategory.name
            }
          });
          this.selectedSubCategory = '';
          this.selectedProducts = [];
          this.allSelected = false;
        }
      });
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedProducts = [];
        this.allSelected = false;
      } else {
        this.selectedProducts = this.newProducts.map(i => i._id); // eslint-disable-line
        this.allSelected = true;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
    createCsv2() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv2();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${item[0].charAt(0).toUpperCase() +
                        item[0].slice(1)} is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.productDetails.storeids.filter(store =>
                  newAddingStore.includes(store.value)
                );
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already there!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.productDetails.storeids = [...this.productDetails.storeids, ...this.abc];
                return null;
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    async onUpdateProduct() {
      const storeids = [];
      this.productDetails.storeids.map(({ value }) => storeids.push(value));

      const regions = [];
      this.productDetails.regionids.map(({ value }) => regions.push(value));

      const clusters = [];
      this.productDetails.clusterids.map(({ value }) => clusters.push(value));

      const formats = [];
      this.productDetails.formats.map(({ value }) => formats.push(value));

      const {
        title,
        active,
        description,
        articleCode,
        mrp,
        discount,
        offers,
        sellingPrice,
        images,
        category,
        subCategory,
        startDate,
        endDate
      } = this.productDetails;
      const params = {
        title,
        active,
        description,
        articleCode,
        mrp,
        discount,
        offers,
        sellingPrice,
        images,
        category,
        subCategory,
        startDate,
        endDate,
        storeids,
        regions,
        clusters,
        formats
      };
      this.editProduct({ params, productId: this.selectProduct._id, router }); // eslint-disable-line
      this.$refs.modalProduct.hide();
    },
    async onDeleteProduct() {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',

        preConfirm: () => {
          this.deleteProduct({
            productId: this.selectProduct._id, // eslint-disable-line
            sort: true,
            router,
            params: {
              category: this.selectProduct.category,
              subCategory: this.selectProduct.subCategory
            }
          });
        }
      });
      this.$refs.modalProduct.hide();
    },
    async onAddProduct() {
      const storeids = [];
      this.productDetails.storeids.map(({ value }) => storeids.push(value));

      const regions = [];
      this.productDetails.regionids.map(({ value }) => regions.push(value));

      const clusters = [];
      this.productDetails.clusterids.map(({ value }) => clusters.push(value));

      const formats = [];
      this.productDetails.formats.map(({ value }) => formats.push(value));

      const {
        title,
        active,
        description,
        articleCode,
        mrp,
        discount,
        offers,
        sellingPrice,
        images,
        category,
        subCategory,
        startDate,
        endDate
      } = this.productDetails;
      const params = {
        title,
        active,
        description,
        articleCode,
        mrp,
        discount,
        offers,
        sellingPrice,
        images,
        category,
        subCategory,
        startDate,
        endDate,
        storeids,
        regions,
        clusters,
        formats
      };
      this.addProduct({ params, router });
      this.$refs.modalProduct.hide();
      this.productDetails = {
        title: '',
        active: false,
        description: '',
        articleCode: '',
        mrp: 0,
        discount: 0,
        offers: '',
        sellingPrice: 0,
        images: '',
        category: this.selectedSubCategory.parent,
        subCategory: this.selectedSubCategory.name,
        startDate: null,
        endDate: null,
        storeids: [],
        regionids: [],
        clusterids: [],
        formats: []
      };
    },
    onAddNewProduct() {
      this.productDetails = {
        title: '',
        active: false,
        description: '',
        articleCode: '',
        mrp: 0,
        discount: 0,
        offers: '',
        sellingPrice: 0,
        images: '',
        category: '',
        subCategory: '',
        startDate: null,
        endDate: null,
        storeids: [],
        regionids: [],
        clusterids: [],
        formats: []
      };
    },
    createCsv() {
      const csv = `Title,Description,Article Code,Images,Store ID,startDate(MM-DD-YYYY),endDate(MM-DD-YYYY)\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'productFile.csv';
      anchor.click();
    },
    createDirectCsv() {
      const csv = `Category, Sub-Category, Title,Description,Article Code,Images,Store ID,startDate(MM-DD-YYYY),endDate(MM-DD-YYYY)\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'productFile.csv';
      anchor.click();
    },
    async onClickUploadProduct() {
      await Vue.swal({
        title: 'Upload file for Products..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Products file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (this.selectedSubCategory) {
          if (result.isDenied) {
            this.createCsv();
          }
          if (result.value) {
            const reader = new FileReader();
            reader.onload = () => {
              csv({
                noheader: true,
                output: 'csv'
              })
                .fromString(reader.result)
                .then(csvRow => {
                  const inputs = csvRow.slice(1, csvRow.length).map(item => {
                    const values = item.reduce((acc, curr, index) => {
                      const storeids = item[4] ? item[4].split(',') : [];
                      const storesCap = storeids.map(
                        store => store.charAt(0).toUpperCase() + store.slice(1).toLowerCase()
                      );
                      if (index === 0) {
                        acc.title = curr;
                      }
                      if (index === 1) {
                        acc.description = curr;
                      }
                      if (index === 2) {
                        acc.articleCode = curr;
                      }
                      if (index === 3) {
                        acc.images = curr;
                      }
                      if (index === 4) {
                        if (storesCap.length >= 1) {
                          for (let i = 0; i < storesCap.length; i += 1) {
                            const checkStoreIds = this.user?.storeids.filter(store => store === storesCap[i]);
                            if (checkStoreIds.length === 0) {
                              Vue.swal({
                                title: 'Invalid Store Id',
                                text: `Store ${storesCap[i]} is not assigned to you! Please enter the correct store`,
                                type: 'error',
                                confirmButtonText: 'Ok'
                              });
                              return null;
                            }
                          }
                        }
                        acc.storeids = storesCap;
                      }

                      if (index === 5) {
                        acc.startDate = moment(curr).format('YYYY-MM-DD');
                      }
                      if (index === 6) {
                        acc.endDate = moment(curr).format('YYYY-MM-DD');
                      }
                      acc.category = this.selectedSubCategory.parent;
                      acc.cluster = [];
                      acc.formats = [];
                      acc.offers = '';
                      acc.regions = [];
                      acc.active = true;
                      acc.subCategory = this.selectedSubCategory.name;
                      return acc;
                    }, {});
                    return values;
                  });
                  this.uploadProduct({ data: inputs, router });
                });
            };
            reader.readAsBinaryString(result.value);
          }
        } else {
          Vue.swal({
            title: 'Please Select Sub Category',
            text: '',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      });
    },
    async onClickDirectUploadProduct() {
      await Vue.swal({
        title: 'Upload file for Products..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Products file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createDirectCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const categoryListTemp = this.categoryList.filter(i => i.type === '1').map(item => item.name);
                const subCategoryListTemp = this.categoryList
                  .filter(i => i.type === '2')
                  .map(j => {
                    return {
                      category: j.parent,
                      subCategory: j.name
                    };
                  });
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  const values = item.reduce((acc, curr, index) => {
                    const storeids = item[6] ? item[6].split(',') : [];
                    const storesCap = storeids.map(
                      store => store.charAt(0).toUpperCase() + store.slice(1).toLowerCase()
                    );
                    if (index === 0) {
                      const capitalCurr = curr.toUpperCase();
                      if (!categoryListTemp.includes(capitalCurr)) {
                        Vue.swal({
                          title: 'Invalid Category',
                          text: `Category ${curr} is not in database. Please check the category and upload accordingly`,
                          type: 'error',
                          confirmButtonText: 'Ok'
                        });
                        return null;
                      }
                      acc.category = capitalCurr;
                    }
                    if (index === 1) {
                      const validateComb = subCategoryListTemp.find(
                        combo =>
                          combo.category === acc.category && combo.subCategory.toUpperCase() === curr.toUpperCase()
                      );
                      if (!validateComb) {
                        Vue.swal({
                          title: 'Invalid Sub Category',
                          text: `Sub-Category ${curr} & ${acc.category} does not match. Please add accordingly`,
                          type: 'error',
                          confirmButtonText: 'Ok'
                        });
                        return null;
                      }
                      acc.subCategory = validateComb.subCategory;
                    }
                    if (index === 2) {
                      acc.title = curr;
                    }
                    if (index === 3) {
                      acc.description = curr;
                    }
                    if (index === 4) {
                      acc.articleCode = curr;
                    }
                    if (index === 5) {
                      acc.images = curr;
                    }
                    if (index === 6) {
                      if (storesCap.length >= 1) {
                        for (let i = 0; i < storesCap.length; i += 1) {
                          const checkStoreIds = this.user?.storeids.filter(store => store === storesCap[i]);
                          if (checkStoreIds.length === 0) {
                            Vue.swal({
                              title: 'Invalid Store Id',
                              text: `Store ${storesCap[i]} is not assigned to you! Please enter the correct store`,
                              type: 'error',
                              confirmButtonText: 'Ok'
                            });
                            return null;
                          }
                        }
                      }
                      acc.storeids = storesCap;
                    }

                    if (index === 7) {
                      acc.startDate = moment(curr).format('YYYY-MM-DD');
                    }
                    if (index === 8) {
                      acc.endDate = moment(curr).format('YYYY-MM-DD');
                    }
                    acc.cluster = [];
                    acc.formats = [];
                    acc.offers = '';
                    acc.regions = [];
                    acc.active = true;
                    return acc;
                  }, {});
                  return values;
                });
                this.uploadProduct({ data: inputs, router });
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    }
  },
  watch: {
    productsWithCategory(newValue) {
      this.newProducts = newValue;
    },
    categoryList(newvalue) {
      this.categories = newvalue.filter(i => i.type === '1');
      this.subCategories = newvalue.filter(i => i.type === '2' && i.parent === this.selectedCategory.name);
    },
    clusterList(newValue) {
      let comparingClusters = [];
      comparingClusters = newValue.map(cluster => ({
        name: cluster.value
      }));
      this.newClusters = comparingClusters;
      this.clusters = newValue;
    },
    regionList(newValue) {
      let comparingRegions = [];
      comparingRegions = newValue.map(region => ({
        region: region.value
      }));
      this.newRegions = comparingRegions;
      this.regions = newValue;
    },
    formatList(newValue) {
      let comparingFormats = [];
      comparingFormats = newValue.map(format => ({
        format: format.value
      }));
      this.newFormats = comparingFormats;
      this.formats = newValue;
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.value
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.catalogue-section-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gray;
}

.catalogue-section {
  flex: 1;
  // background-color: #f0f2f5;
  // border-right: 1px solid gray;
  min-width: 300px;
  height: 80vh;
  overflow: auto;

  &:last-child {
    border-right: none;
  }
}

.list-item {
  text-align: center;
  background: none;
  border: none;
  outline: none;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 95%;
  margin: 5px 0px 2px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.catalogue-title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: bold;
  // color: #333;
}

.catalogue-title-label {
  color: #333;
  font-size: 20px;
  font-weight: 500;
}

.button-section {
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.top_btn {
  outline: none;
  font-weight: 500;
  color: #f28a3e;
  border-radius: 3px;
  margin-top: 5px;
  background-color: #fff;
  border: 1.5px solid #f28a3e;
}

.top_btn:hover {
  color: #fff;
  background-color: #f28a3e;
}

.product-section {
  flex: 1;
  border: none;
  outline: none;
  // border-right: 1px solid gray;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  min-width: 300px;
  height: 80vh;
  overflow: auto;

  &:last-child {
    border-right: none;
  }
}

.single-product-section {
  flex: 1;
  // background-color: #f0f2f5;
  border-right: 1px solid gray;
  min-width: 300px;
  padding: 20px;
  height: 80vh;
  overflow: auto;

  &:last-child {
    border-right: none;
  }
}

.product-list {
  margin: 5px 0px 2px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.product-title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 12px;
  // color: #333;
}

.checkboxActive {
  width: 20px;
  height: 20px;
  float: left;
  margin-top: 7px;
}

.activeProduct {
  color: green;
  float: right;
  font-size: 25px;
}

.inactiveProduct {
  color: red;
  float: right;
  font-size: 25px;
}

.checkboxAll {
  width: 20px;
  height: 20px;
  margin-left: 3.5%;
  margin-top: 4%;
}

.allSelectLabel {
  margin-bottom: 5px;
  margin-left: 2%;
  vertical-align: bottom;
  font-weight: 400;
  font-size: 15px;
}

.input_label {
  font-size: 14px;
  font-weight: 500;
}

.single-product-section-input {
  border-radius: 3px;
  border: 1.5px solid #c4c3d0;
}

.uploadStoreButton {
  padding: 2px 7px 2px 7px;
}

.catalogue-title-input {
  border-radius: 3px;
  border: 1.5px solid #c4c3d0;
  width: 95%;
  height: 40px;
  padding-left: 5px;
  margin: 5px 0px 2px 0px;
}
</style>

<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
